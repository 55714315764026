import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Splash } from "../../../champs";
import useApi, { BASE_URL } from "../../../hooks/useApi";

import { AlcadethItems, ItemWithImage } from "../../../items";
import { FormattedRune } from "../../../runes";
import Item from "../../atoms/Item";
import Rune from "../../atoms/Rune";
import ItemContainer from "../ItemContainer";
import RuneContainer from "../RuneContainer";

type Props = {
  character: string;
  build?: Build;
  closeModal: () => void;
};

export const BuildCreatorModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "55vh",
    maxHeight: "85vh",
    width: "65vw",
    padding: 0,
    display: "flex",
    flexDirection: "column" as any,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 4,
  },
};

export type BuildCategory = { title: string; items: ItemWithImage[] };

export type Build = {
  _id?: string;
  splashArt: string;
  content?: string;
  name: string;
  champ: string;
  buildCategories: BuildCategory[];
  recommendedRunes: FormattedRune[];
  exampleItemBuilds: ItemWithImage[][];
};

const DEFAULT_BUILD_CATEGORY = {
  title: "",
  items: [],
  runes: [],
};

const DEFAULT_BUILD: Build = {
  _id: "",
  splashArt: "",
  buildCategories: [],
  recommendedRunes: [],
  exampleItemBuilds: [],
  content: "",
  name: "",
  champ: "",
};

const BuildCreatorModal: FunctionComponent<Props> = ({
  build = DEFAULT_BUILD,
  character,
  closeModal,
}) => {
  const [backdrop, setBackdrop] = useState<string>("");
  const [recommendedRunes, setRecommendedRunes] = useState<FormattedRune[]>([]);
  const [buildCategories, setBuildCategories] = useState<BuildCategory[]>([]);
  const [buildName, setBuildName] = useState<string>("");

  const [selectedExampleItemBuildIndex, setSelectedExampleItemBuildIndex] =
    useState(0);
  const [exampleItemBuilds, setExampleItemBuilds] = useState<ItemWithImage[][]>(
    [[]]
  );
  const [buildCategory, setBuildCategory] = useState<BuildCategory>(
    DEFAULT_BUILD_CATEGORY
  );

  useEffect(() => {
    if (buildCategories.length === 0 && build) {
      setBuildCategories(build.buildCategories);
    }
    if (!backdrop && build) {
      setBackdrop(build.splashArt);
    }
    if (recommendedRunes.length === 0 && build) {
      setRecommendedRunes(build.recommendedRunes);
    }
  }, [build, buildCategories, backdrop, recommendedRunes]);

  const { data: items } = useApi<AlcadethItems>("lol/items");
  const { data: runes } = useApi<FormattedRune[]>("lol/runes");
  // const { data: splashes = [] } = useApi<Splash[]>(
  //   `lol/champs/splashes?champ=${character}`
  // );

  // useEffect(() => {
  //   if (!backdrop && !build.splashArt && !!splashes.length) {
  //     setBackdrop(splashes[0].url);
  //   }
  // }, [backdrop, splashes, character]);

  const saveBuildCategory = () => {
    setBuildCategory(DEFAULT_BUILD_CATEGORY);
    setBuildCategories([...buildCategories, buildCategory]);
  };

  const removeBuildCategory = () => {
    setBuildCategory(DEFAULT_BUILD_CATEGORY);
    const categoriesFiltered = buildCategories.filter(
      (category) => category.title !== buildCategory.title
    );
    setBuildCategories(categoriesFiltered);
  };

  const setBuildCategoryName = (value: string) => {
    setBuildCategory({ items: buildCategory?.items, title: value });
  };

  const addRecommendedRune = (runeToAdd: FormattedRune) => {
    if (recommendedRunes.length === 4) {
      return;
    }
    const includesItem = recommendedRunes.some(
      (rune) => rune.name === runeToAdd.name
    );
    if (includesItem) {
      return;
    }
    setRecommendedRunes([...recommendedRunes, runeToAdd]);
  };

  const removeRecommendedRune = (runeToRemove: FormattedRune) => {
    const filteredRecommendedRunes = recommendedRunes.filter(
      (rune) => rune.name !== runeToRemove.name
    );
    setRecommendedRunes(filteredRecommendedRunes);
  };

  const addItemToCategory = (item: ItemWithImage) => {
    const includesItem = buildCategory.items.some(
      (catItem) => catItem.name === item.name
    );
    if (includesItem) {
      return;
    }
    return setBuildCategory({
      title: buildCategory.title,
      items: [...buildCategory.items, item],
    });
  };

  const removeCategoryItem = (item: ItemWithImage) => {
    const filteredItems = buildCategory.items.filter(
      (listItem) => listItem.name !== item.name
    );
    return setBuildCategory({
      title: buildCategory.title,
      items: filteredItems,
    });
  };

  const saveBuild = async () => {
    const build = {
      buildCategories,
      splashArt: backdrop,
      recommendedRunes,
      exampleItemBuilds,
      name: buildName,
      champ: character,
    };
    await fetch(`${BASE_URL}/builds`, {
      body: JSON.stringify(build),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const addExampleBuild = () => {
    setExampleItemBuilds([...exampleItemBuilds, []]);
  };

  const addItemToExampleBuild = (item: ItemWithImage) => {
    const builds = exampleItemBuilds;
    const exampleBuild = exampleItemBuilds[selectedExampleItemBuildIndex];

    if (exampleBuild.some((buildItem) => buildItem.name === item.name)) {
      return;
    }
    const buildWithItem = [...exampleBuild, item];
    builds[selectedExampleItemBuildIndex] = buildWithItem;
    setExampleItemBuilds(builds);
  };

  const removeItemFromExampleBuild = (item: ItemWithImage) => {
    const builds = exampleItemBuilds;
    const exampleBuild = exampleItemBuilds[selectedExampleItemBuildIndex];
    const buildWithOutItem = exampleBuild.filter(
      (buildItem) => buildItem.name !== item.name
    );
    builds[selectedExampleItemBuildIndex] = buildWithOutItem;
    return setExampleItemBuilds(builds);
  };

  const ICON_SIZE = 56;

  return (
    <>
      <header
        style={{
          padding: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <button onClick={closeModal}>X</button>
          <div style={{ width: 8 }}></div>
          <div
            style={{
              display: "flex",
            }}
          >
            {buildCategories.map((build) => (
              <div
                key={build.title}
                style={{
                  background: "black",
                  minWidth: 75,
                  maxWidth: 120,
                  borderRadius: "0px 0px 0px 0px",
                  color: "white",
                  padding: 8,
                  fontWeight: 600,
                }}
                onClick={() => setBuildCategory(build)}
              >
                {build.title}
              </div>
            ))}
          </div>
        </div>
        <span
          style={{ marginLeft: "auto" }}
        >{`Build Creator - ${character}`}</span>
      </header>

      <div
        style={{
          display: "flex",
          padding: 8,
          overflow: "hidden",
          height: (ICON_SIZE + 10) * 7,
        }}
      >
        <div
          style={{
            minWidth: 100,
            overflowWrap: "break-word",
            background: "rgba(55,55,55,0.7)",
            borderRadius: 6,
            marginRight: 8,
            padding: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{ color: "white", textAlign: "center", fontWeight: 600 }}
          >
            {buildCategory.title}
          </span>
          <div
            style={{
              display: "grid",
              gridGap: 8,
              maxHeight: "100%",
              overflow: "scroll",
            }}
          >
            {buildCategory.items.map((item) => (
              <Item
                key={item.name}
                item={item}
                size={ICON_SIZE}
                onClick={removeCategoryItem}
              />
            ))}
          </div>
        </div>
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(255,255,255,0.5)",
            borderRadius: 8,
            padding: 8,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 8,
              }}
            >
              <label htmlFor="buildName">Build Name</label>
              <input
                id="buildName"
                name="buildName"
                onChange={({ target: { value } }) => setBuildName(value)}
              />
              <label htmlFor="buildCategory">Build Category</label>
              <input
                id="buildCategory"
                name="buildCategory"
                onChange={({ target: { value } }) =>
                  setBuildCategoryName(value)
                }
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", padding: 8 }}
            >
              <label htmlFor="buildBackdrop">Build Backdrop</label>
              <select
                id="buildBackdrop"
                name="buildBackdrop"
                defaultValue={"default"}
                onChange={({ target: { value } }) => setBackdrop(value)}
              >
                {/* {splashes?.length > 0 &&
                  splashes?.map((splash) => (
                    <option key={splash.name} value={splash.url}>
                      {splash.name}
                    </option>
                  ))} */}
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              columnGap: 4,
              height: "100%",
              overflow: "scroll",
            }}
          >
            {items?.mythics && (
              <ItemContainer
                includeFilter
                grid
                handlePress={addItemToCategory}
                items={items.mythics}
                title="Mythics"
                size={ICON_SIZE}
                backgroundColor="rgba(255,255,255,0.5)"
              />
            )}

            {items?.legendaries && (
              <ItemContainer
                includeFilter
                grid
                handlePress={addItemToCategory}
                items={items.legendaries}
                title="Legendaries"
                size={ICON_SIZE}
                backgroundColor="rgba(0,0,0,0.5)"
                titleColor="white"
              />
            )}

            {items?.boots && (
              <ItemContainer
                includeFilter
                grid
                handlePress={addItemToCategory}
                items={items.boots}
                title="Boots"
                size={ICON_SIZE}
                backgroundColor="rgba(255,255,255,0.5)"
              />
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          padding: 8,
          maxHeight: ICON_SIZE * 4 + 8 * 5,
        }}
      >
        <div
          style={{
            minWidth: 100,
            overflowWrap: "break-word",
            background: "rgba(55,55,55,0.7)",
            borderRadius: 6,
            marginRight: 8,
            padding: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              gridGap: 8,
              maxHeight: "100%",
            }}
          >
            {recommendedRunes.map((rune) => (
              <Rune
                key={rune.name}
                rune={rune}
                size={ICON_SIZE}
                onClick={removeRecommendedRune}
              />
            ))}
          </div>
        </div>
        <div style={{ overflow: "hidden" }}>
          {!!runes?.length && (
            <RuneContainer
              runes={runes}
              handlePress={addRecommendedRune}
              size={ICON_SIZE}
              backgroundColor="rgba(255,255,255,0.5)"
            />
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          padding: 8,
          overflow: "hidden",
          height: (ICON_SIZE + 10) * 7,
        }}
      >
        {exampleItemBuilds.map((exampleBuild, index) => (
          <div
            onClick={() => setSelectedExampleItemBuildIndex(index)}
            style={{
              minWidth: 100,
              overflowWrap: "break-word",
              background:
                selectedExampleItemBuildIndex === index
                  ? "white"
                  : "rgba(55,55,55,0.7)",
              borderRadius: 6,
              marginRight: 8,
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color:
                  selectedExampleItemBuildIndex === index ? "black" : "white",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Example {index + 1}
            </span>
            <div
              style={{
                display: "grid",
                gridGap: 8,
                maxHeight: "100%",
                overflow: "scroll",
              }}
            >
              {exampleBuild.map((item) => (
                <Item
                  key={item.name}
                  item={item}
                  size={ICON_SIZE}
                  onClick={removeItemFromExampleBuild}
                />
              ))}
            </div>
          </div>
        ))}
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(255,255,255,0.5)",
            borderRadius: 8,
            padding: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              columnGap: 4,
              height: "100%",
              overflow: "scroll",
            }}
          >
            {items?.mythics && (
              <ItemContainer
                includeFilter
                grid
                handlePress={addItemToExampleBuild}
                items={items.mythics}
                title="Mythics"
                size={ICON_SIZE}
                backgroundColor="rgba(255,255,255,0.5)"
              />
            )}

            {items?.legendaries && (
              <ItemContainer
                includeFilter
                grid
                handlePress={addItemToExampleBuild}
                items={items.legendaries}
                title="Legendaries"
                size={ICON_SIZE}
                backgroundColor="rgba(0,0,0,0.5)"
                titleColor="white"
              />
            )}

            {items?.boots && (
              <ItemContainer
                includeFilter
                grid
                handlePress={addItemToExampleBuild}
                items={items.boots}
                title="Boots"
                size={ICON_SIZE}
                backgroundColor="rgba(255,255,255,0.5)"
              />
            )}
          </div>
        </div>
        <div>
          <button onClick={addExampleBuild}>Add Example Build</button>
        </div>
      </div>

      <footer
        style={{
          position: "sticky",
          bottom: 0,
          width: "100%",
        }}
      >
        <button onClick={saveBuildCategory}>save me</button>
        <button onClick={() => setBuildCategory(DEFAULT_BUILD_CATEGORY)}>
          clear
        </button>
        {!!buildCategory.items.length && buildCategory.title && (
          <button onClick={() => removeBuildCategory()}>delete</button>
        )}
        <button style={{ float: "right" }} onClick={saveBuild}>
          SAVE BUILD
        </button>
      </footer>
      {backdrop && (
        <img
          style={{
            backgroundImage: `url(${backdrop})`,
            backgroundSize: "cover",
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
          alt=""
        />
      )}
    </>
  );
};

export default BuildCreatorModal;
