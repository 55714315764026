import React, { FunctionComponent, useState } from "react";
import * as S from "./styled";
import SideNavigationMenu from "../../components/molecules/SideNavigationMenu";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { Build } from "../../components/molecules/BuildCreatorModal/BuildCreatorModal";
import ChampBuild from "./components/ChampBuild";
import { Champ } from "../../champs";
import CharacterBuild from "../CharacterPage/modals/CharacterBuild";
import CharacterWYSIWYG from "../CharacterPage/modals/CharacterWYSIWYG";
import Modal from "react-modal";

type Props = {};

enum ModalType {
  BUILD = "BUILD",
  CONTENT = "CONTENT",
}

export const BuildCreatorModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "55vh",
    maxHeight: "85vh",
    width: "65vw",
    padding: 0,
    display: "flex",
    flexDirection: "column" as any,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 4,
  },
};

const DEFAULT_BUILD = {
  _id: "",
  splashArt: "",
  buildCategories: [],
  recommendedRunes: [],
  exampleItemBuilds: [],
  name: "",
  champ: "",
  content: "",
};

const CharacterLanding: FunctionComponent<Props> = () => {
  const { character } = useParams();
  const { data: builds, isLoading } = useApi<Build[]>(
    `builds/champs/${character}`,
    []
  );
  const { data: champ } = useApi<Champ>(`lol/champs/${character}`);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [content, addContent] = useState("");
  const [modalType, setModalType] = useState<ModalType>();
  function openModal(modalType: ModalType) {
    setModalType(modalType);
    setIsOpen(true);
  }
  const modal =
    modalType === ModalType.BUILD
      ? {
          component: (
            <CharacterBuild
              character={character as string}
              build={DEFAULT_BUILD}
              closeModalFn={() => setIsOpen(!modalIsOpen)}
            />
          ),
          label: "Create Build Category",
        }
      : {
          component: (
            <CharacterWYSIWYG contentHandler={[content, addContent]} />
          ),
          label: "Create Character Content",
        };

  console.log({ builds });
  return (
    <S.Container>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "#222222",
          display: "flex",
        }}
      >
        <SideNavigationMenu />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            overflowY: "scroll",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <S.Title>{character} builds</S.Title>
            <S.Subtitle>{champ?.title}</S.Subtitle>
          </div>
          <div style={{ height: 28 }}></div>
          {!builds?.length && <span>No builds found</span>}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            {builds?.map((build) => (
              <ChampBuild key={build._id} build={build} />
            ))}
            <div>
              <button onClick={() => openModal(ModalType.BUILD)}>
                Edit Build
              </button>
              <button onClick={() => openModal(ModalType.CONTENT)}>
                Add Text
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(!modalIsOpen)}
        style={BuildCreatorModalStyles}
        contentLabel={modal.label}
        ariaHideApp={false}
      >
        {modal.component}
      </Modal>
    </S.Container>
  );
};

export default CharacterLanding;
