import styled from "styled-components";

type ItemsContainerProps = {
  grid: boolean;
  gridAmount: number;
  size: number;
};

export const ItemsContainer = styled.div<ItemsContainerProps>`
  overflow: scroll;
  height: ${({ grid }) => (grid ? "auto" : "100%")};
  display: ${({ grid }) => (grid ? "grid" : "flex")};
  grid-template-columns: ${({ gridAmount }) =>
    new Array(gridAmount)
      .fill(null)
      .map((i) => "auto")
      .join(" ")};
  flex-direction: column;
  align-items: center;
  width: ${({ size, grid }) => (grid ? "100%" : `${size + 22}px`)};
  grid-gap: 4px;
`;
