import styled from "styled-components";

type RuneContainerProps = {
  backgroundColor: string;
};

export const RunesContainer = styled.div<RuneContainerProps>`
  overflow: scroll;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 8px;
  border-radius: 8px;
`;
