import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const ItemContainer = styled.div`
  width: 100%;
  height: min-content;
  flex-basis: 30%;
  border-radius: 6px;
  background-color: #292929;
`;

export const Title = styled.span`
  font-family: "Cabin", sans-serif;
  font-size: 24px;
  color: #fff;
`;

export const Subtitle = styled.span`
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  color: #fff;
  opacity: 0.4;
`;
