import React, { FunctionComponent, useState } from "react";
import * as S from "./styled";
import { FormattedRune } from "../../../runes";

type Props = {
  rune: FormattedRune;
  size: number;
  onClick?: any;
  borderColor?: string;
};

const Rune: FunctionComponent<Props> = ({
  rune,
  size,
  onClick,
  borderColor = "black",
}) => {
  return (
    <S.Container
      borderColor={borderColor}
      onClick={() => onClick(rune)}
      size={size}
    >
      <S.ItemImage src={rune.imageUrl} id={rune.name + "/img"} alt="item" />
    </S.Container>
  );
};

export default Rune;
