import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: #1d1d1d;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  box-sizing: border-box;
  flex-basis: 33%;
`;

export const Image = styled.div<{ backgroundImage: string }>`
  height: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  opacity: 1;
  cursor: pointer;
`;

export const Avatar = styled.div`
  min-width: 75px;
  width: 75px;
  height: 75px;
  background-image: url(https://static-cdn.jtvnw.net/jtv_user_pictures/ba97a10e-b923-4b81-a2be-6698c84c3864-profile_image-150x150.png);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
`;

export const Text = styled.span`
  position: relative;
  color: white;
  text-align: left;
  width: 100%;
`;

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const LiveIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a90000;
  animation: ${pulsate} 2s ease-in-out infinite;
`;

export const LiveText = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: white;
`;

export const InformationRow = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
