import React from "react";

import BuildCreatorModal from "../../../components/molecules/BuildCreatorModal";
import { Build } from "../../../components/molecules/BuildCreatorModal/BuildCreatorModal";

type Props = {
  character: string;
  build: Build;
  closeModalFn: () => void;
};

const CharacterBuild: React.FC<Props> = ({
  build,
  character,
  closeModalFn,
}) => {
  return (
    <BuildCreatorModal
      character={character}
      build={build}
      closeModal={closeModalFn}
    />
  );
};

export default CharacterBuild;
