import React from "react";
import styled from "styled-components";
type FillType = "fill" | "stroke";

const SvgWrapper = styled.div<{ fillType: FillType }>`
  cursor: pointer;
  ${({ fillType }) => `
  &:hover svg path {
    ${fillType}: white;
  }
  `}
`;

type Props = {
  onPress: () => void;
  svg: JSX.Element;
  fillType: FillType;
};

const Social = ({ onPress, svg, fillType = "fill" }: Props) => {
  return (
    <SvgWrapper fillType={fillType} onClick={onPress}>
      {svg}
    </SvgWrapper>
  );
};

export default Social;
