import React, {
  DetailedHTMLProps,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

import { ItemWithImage } from "../../../items";
import { FormattedRune } from "../../../runes";
import Item from "../../atoms/Item";
import Rune from "../../atoms/Rune";
import * as S from "./styled";

type Props = {
  runes: FormattedRune[];
  handlePress: (rune: FormattedRune) => void;
  size: number;
  backgroundColor?: string;
};

const ItemContainer: FunctionComponent<Props> = ({
  runes,
  handlePress,
  size = 64,
  backgroundColor = "white",
}) => {
  return (
    <S.RunesContainer backgroundColor={backgroundColor}>
      {runes?.map((rune, index) => (
        <Rune key={rune.name} rune={rune} size={size} onClick={handlePress} />
      ))}
    </S.RunesContainer>
  );
};

export default ItemContainer;
