import React, { FunctionComponent } from "react";
import * as S from "./styled";
import SideNavigationMenu from "../../components/molecules/SideNavigationMenu";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { AlcadethItems, ItemResponse, ItemWithImage } from "../../items";

type Props = {};

const ItemLanding: FunctionComponent<Props> = () => {
  const { item } = useParams();
  const { data: itemData } = useApi<ItemWithImage>(`lol/items/${item}`);
  console.log(itemData);
  return (
    <S.Container>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "#222222",
          display: "flex",
        }}
      >
        <SideNavigationMenu />
        <S.ItemContainer>
          {itemData && (
            <img
              src={itemData.imageUrl}
              style={{ width: 450, objectFit: "cover" }}
              alt="item"
            />
          )}
          <div
            style={{ display: "flex", flexDirection: "column", padding: 20 }}
          >
            <S.Title>{itemData?.name}</S.Title>
            <S.Subtitle>{itemData?.description}</S.Subtitle>
          </div>
        </S.ItemContainer>
      </div>
    </S.Container>
  );
};

export default ItemLanding;
