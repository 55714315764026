import React, {
  DetailedHTMLProps,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

import { ItemWithImage } from "../../../items";
import Item from "../../atoms/Item";
import * as S from "./styled";

type Props = {
  items: ItemWithImage[];
  title: string;
  handlePress: (item: ItemWithImage) => void;
  size: number;
  grid: boolean;
  backgroundColor?: string;
  titleColor?: string;
  includeFilter?: boolean;
  gridAmount?: number;
};

const ItemContainer: FunctionComponent<Props> = ({
  items,
  title,
  handlePress,
  size = 64,
  grid,
  backgroundColor = "white",
  titleColor = "black",
  includeFilter = false,
  gridAmount = 3,
}) => {
  const [displayItems, setDisplayItems] = useState<ItemWithImage[]>(items);
  const filterItems = (value: string) => {
    const newItems = items.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    return setDisplayItems(newItems);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor,
        padding: 8,
        borderRadius: 8,
      }}
    >
      {includeFilter && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <input
            placeholder="Filter Items"
            id="filterMythic"
            name="filterMythic"
            onChange={({ target: { value } }) => filterItems(value)}
          />
        </div>
      )}
      <span style={{ color: titleColor, fontWeight: 600, fontSize: 16 }}>
        {title}
      </span>
      <S.ItemsContainer grid={grid} gridAmount={gridAmount} size={size}>
        {displayItems?.map((item, index) => (
          <Item key={item.name} item={item} size={size} onClick={handlePress} />
        ))}
      </S.ItemsContainer>
    </div>
  );
};

export default ItemContainer;
