import styled from "styled-components";

export const Container = styled.div``;

export const ChampName = styled.span<{ selected: boolean }>`
  font-family: "Cabin", sans-serif;
  font-size: 14px;
  color: ${({ selected }) => (selected ? "#988038" : "#fff")};
`;

export const ChampTitle = styled.span<{ selected: boolean }>`
  font-family: "Cabin", sans-serif;
  font-size: 12px;
  opacity: 0.5;
  color: ${({ selected }) => (selected ? "#988038" : "#fff")};
  max-width: 130px;
`;

export const ChampLinkContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  border: ${({ selected }) => (selected ? "1px solid #766B4A" : "none")};
`;

// 988038
