import React, { FunctionComponent, useState, useEffect } from "react";
import useSWR from "swr";
import useApi from "../../../hooks/useApi";
import streamBG from "./stream-bg.png";
import * as S from "./styled";

type Props = {
  socials?: React.ReactNode[];
};

const StreamStatus: FunctionComponent<Props> = ({ socials }) => {
  const { data } = useApi<any>("twitch/statuses?channel=aribolol");
  const handleBgClick = () => {
    window.open("https://www.twitch.tv/aribolol");
  };
  return (
    <S.Container>
      <div
        style={{
          padding: 4,
          marginRight: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {data ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <S.LiveText>LIVE</S.LiveText>
            <div style={{ width: 2 }} />
            <S.LiveIndicator />
          </div>
        ) : (
          <div style={{ height: 16 }} />
        )}
        <div style={{ display: "flex", gap: 4 }}>{socials}</div>
      </div>
      <S.Image
        onClick={handleBgClick}
        backgroundImage={
          data?.thumbnail_url || "https://i.imgur.com/TpRMYjA.png"
        }
      />
      <div style={{ height: 16 }} />
      <S.InformationRow onClick={handleBgClick}>
        <S.Avatar />
        <S.Text>{data?.title || "offline"}</S.Text>
      </S.InformationRow>
    </S.Container>
  );
};

export default StreamStatus;
