import { useState, useEffect, useCallback } from "react";
import { Champ } from "../champs";

type FavoriteAction = { type: "load"; payload: Champ[] };

const useFavoriteChamp = () => {
  const [favorites, setFavorites] = useState<Champ[]>([]);

  const loadFavorites = useCallback(() => {
    const savedFavorites = localStorage.getItem("favorites");
    if (savedFavorites) {
      const parsedFavorites = JSON.parse(savedFavorites) as Champ[];
      setFavorites(parsedFavorites);
    }
  }, []);

  useEffect(() => {
    loadFavorites();
  }, [loadFavorites]);

  const toggleFavorite = useCallback((champ: Champ) => {
    setFavorites((currentFavorites) => {
      const index = currentFavorites.findIndex((f) => f.id === champ.id);
      if (index === -1) {
        return [...currentFavorites, champ];
      } else {
        return currentFavorites.filter((c) => c.id !== champ.id);
      }
    });
  }, []);

  useEffect(() => {
    if (favorites.length > 0) {
      localStorage.setItem("favorites", JSON.stringify(favorites));
    }
  }, [favorites]);

  const isFavorite = useCallback(
    (id: string) => {
      return favorites.findIndex((champ) => champ.id === id) !== -1;
    },
    [favorites]
  );

  return { favorites, toggleFavorite, isFavorite };
};

export default useFavoriteChamp;
