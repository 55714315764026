import styled from "styled-components";

type ContainerProps = {
  borderColor: string;
  size: number;
};

export const Container = styled.div<ContainerProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
`;

export const ItemImage = styled.img`
  height: 100%;
  width: 100%;
`;
