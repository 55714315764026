import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const Title = styled.span`
  font-family: "Cabin", sans-serif;
  font-size: 24px;
  color: #fff;
`;

export const Subtitle = styled.span`
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  color: #fff;
  opacity: 0.4;
`;
