import React from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css"; // import the dark mode theme CSS file

type Props = {
  contentHandler: [string, (html: string) => void];
};

const CharacterWYSIWYG: React.FC<Props> = ({ contentHandler }) => {
  const [content, addContent] = contentHandler;
  return (
    <ReactQuill
      theme="snow"
      style={{ color: "white", background: "#222222" }}
      modules={{
        toolbar: [
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ color: [] }, { background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],

          ["link", "image", "video"],
          ["clean"],
        ],
      }}
      value={content}
      onChange={addContent}
    />
  );
};

export default CharacterWYSIWYG;
