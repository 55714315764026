import React, { FunctionComponent } from "react";
import * as S from "./styled";
import { Build } from "../../../../components/molecules/BuildCreatorModal/BuildCreatorModal";

type Props = {
  build: Build;
};

const ChampBuild: FunctionComponent<Props> = ({ build }) => {
  return (
    <S.Container>
      <img
        src={build.splashArt}
        style={{ width: "100%", height: "80%", objectFit: "cover" }}
        alt="champ"
      />
      <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
        <S.Title>{build.name}</S.Title>
        <S.Description>
          Lorem ipsum dolor sit amet consectetur. Massa augue adipiscing cras
          quam vitae. Nibh sollicitudin nisl et euismod pretium non.
        </S.Description>
      </div>
    </S.Container>
  );
};

export default ChampBuild;
