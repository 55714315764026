import { useState } from "react";
import { FaSearch } from "react-icons/fa";

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

function SearchBar({ onSearch }: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <div
      className="search-bar"
      style={{
        background: "#040404",
        padding: 10,
        alignItems: "center",
        display: "flex",
        borderRadius: 4,
      }}
    >
      <FaSearch color="#FFFFFF" opacity={0.4} />
      <div style={{ width: 10 }}></div>
      <input
        type="text"
        placeholder="Search for a champion"
        value={searchTerm}
        onChange={handleSearch}
        style={{
          background: "none",
          border: "none",
          outline: "none",
          color: "#FFFFFF",
        }}
      />
    </div>
  );
}

export default SearchBar;
