import useSWR from "swr";

// export const BASE_URL = "http://127.0.0.1:5001/alcadeth/europe-west1/api/v1";
export const BASE_URL =
  "https://europe-west1-alcadeth.cloudfunctions.net/api/v1";

async function fetcher(url: string) {
  const res = await fetch(url);
  return res.json();
}

function useApi<T>(url: string, initialData?: T) {
  const { data, error, isLoading } = useSWR<T>(`${BASE_URL}/${url}`, fetcher, {
    fallbackData: initialData,
  });
  return {
    data: initialData ? (data as T) : data,
    isLoading,
    isError: error,
  };
}

export default useApi;
