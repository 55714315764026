import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import App from "../App";
import CharacterPage from "../screens/CharacterPage";
import CharacterLanding from "../screens/CharacterLanding";
import ItemLanding from "../screens/ItemLanding/ItemLanding";

function Main() {
  const [navState, setNavState] = useState({
    isReady: false,
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/characters/:character" element={<CharacterLanding />} />
        <Route path="/items/:item" element={<ItemLanding />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
