import React, { FunctionComponent, useState } from "react";
import { ItemWithImage } from "../../../items";
import * as S from "./styled";
import Tippy from "@tippyjs/react";

type Props = {
  item: ItemWithImage;
  size: number;
  onClick?: any;
  borderColor?: string;
};

const Item: FunctionComponent<Props> = ({
  item,
  size,
  onClick,
  borderColor = "black",
}) => {
  return (
    <Tippy
      content={
        <span style={{ background: "black", color: "white", padding: 4 }}>
          {item.name}
        </span>
      }
    >
      <S.Container
        borderColor={borderColor}
        onClick={() => onClick(item)}
        size={size}
      >
        <S.ItemImage src={item.imageUrl} id={item.name + "/img"} alt="item" />
      </S.Container>
    </Tippy>
  );
};

export default Item;
