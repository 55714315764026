import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: min-content;
  flex-basis: 30%;
  border-radius: 6px;
  background-color: #292929;
`;

export const Title = styled.span`
  font-family: "Cabin", sans-serif;
  font-size: 24px;
  color: #fff;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const Description = styled.span`
  font-family: "Cabin", sans-serif;
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
`;
