import "./App.css";
import SideNavigationMenu from "./components/molecules/SideNavigationMenu";
import StreamStatus from "./components/molecules/StreamStatus";
import FeatherIcon from "feather-icons-react";
import Patreon from "./components/atoms/Patreon";
import Social from "./Social";

function App() {
  const twitterURL = "https://twitter.com/_Aribo_";
  const patreonURL = "https://www.patreon.com/Aribo";
  const socials = [
    <Social
      onPress={console.log}
      fillType="stroke"
      svg={
        <FeatherIcon
          onClick={() => window.open(twitterURL, "_blank")}
          icon="twitter"
          size={24}
          stroke="#666666"
        />
      }
    />,
    <Social
      onPress={() => window.open(patreonURL, "_blank")}
      fillType="fill"
      svg={<Patreon size={24} fill="#666666" />}
    />,
  ];
  return (
    <div
      className="App"
      style={{
        width: "100vw",
        height: "100vh",
        background: "#141414",
        display: "flex",
      }}
    >
      <SideNavigationMenu />
      <div style={{ padding: 32, height: "100%", width: "100%" }}>
        <div
          style={{
            display: "flex",
            height: "calc(100% - 64px)",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <StreamStatus socials={socials} />

          <div
            style={{
              flexBasis: "33%",
              width: "100%",
              background: `url("./public/Alcadeth.png") no-repeat center center / contain`,
            }}
          />
          <div
            style={{
              flexBasis: "33%",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
