import React, { FunctionComponent, useState, useEffect } from "react";
import { Champ, ChampsObject, ChampsResponse } from "../../../champs";
import * as S from "./styled";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import SearchBar from "./components/SearchBar";
import FeatherIcon from "feather-icons-react";
import useFavoriteChamp from "../../../hooks/useFavoriteChamp";
import SearchBarSelector from "./components/SearchBarSelector";
import { ItemResponse } from "../../../items";
import { AlcadethItems } from "../../../items";

type ChampMenuItemProps = {
  name: string;
  image: string;
  itemType: "champ" | "item" | "rune";
  subtitle?: string;
  selected: boolean;
  onPress: () => void;
  isFavorite?: boolean;
};

function MenuListItem({
  name,
  image,
  subtitle,
  itemType,
  selected,
  onPress,
  isFavorite,
}: ChampMenuItemProps) {
  const navLink = () => {
    if (itemType === "champ") {
      return `/characters/${name}`;
    }
    if (itemType === "item") {
      return `/items/${name}`;
    }
    if (itemType === "rune") {
      return `/runes/${name}`;
    }
    return "";
  };
  return (
    <MenuItem routerLink={<Link to={navLink()} />}>
      <div
        style={{
          display: "flex",
          border: selected ? "1px solid #988038" : "none",
          borderRadius: 4,
          padding: 2,
          paddingRight: 10,
          boxSizing: "border-box",
          background: selected ? "rgba(125, 121, 109, 0.2)" : "none",
        }}
      >
        <img src={image} style={{ width: 41, height: 41 }} alt="champ" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 8,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <S.ChampName selected={selected}>{name}</S.ChampName>
            {subtitle && (
              <S.ChampTitle selected={selected}>{subtitle}</S.ChampTitle>
            )}
          </div>
          <div
            onClick={(event) => {
              event.stopPropagation();

              onPress();
            }}
            style={{ padding: 8, zIndex: 2 }}
          >
            <FeatherIcon
              icon="star"
              size={16}
              stroke={isFavorite ? "none" : "#7D796D"}
              fill={!isFavorite ? "none" : "#7D796D"}
            />
          </div>
        </div>
      </div>
    </MenuItem>
  );
}

type Props = {
  onPress?: () => void;
};

function mergeItems(items?: AlcadethItems) {
  return [
    ...(items?.mythics ?? []),
    ...(items?.legendaries ?? []),
    ...(items?.boots ?? []),
  ];
}

const SideNavigationMenu: FunctionComponent<Props> = ({ onPress }) => {
  const [menuItems, selectMenuItems] = useState<"champs" | "items" | "runes">(
    "champs"
  );
  const { favorites, isFavorite, toggleFavorite } = useFavoriteChamp();
  const { collapseSidebar } = useProSidebar();
  const { data: champs } = useApi<ChampsObject>("lol/champs");
  const { data: items } = useApi<AlcadethItems>("lol/items");

  const itemsArray = mergeItems(items);

  const location = useLocation();
  const decodedPath = decodeURI(location.pathname);
  const [filteredChamps, setFilteredChamps] = useState<Champ[]>([]);

  useEffect(() => {
    if (champs) {
      const champsArray = Object.values(champs);

      const favoriteChamps = champsArray.filter((champ) =>
        favorites.find((f) => f.id === champ.id)
      );
      favoriteChamps.sort((a, b) => a.name.localeCompare(b.name));

      const remainingChamps = champsArray.filter(
        (champ) => !favoriteChamps.includes(champ)
      );
      remainingChamps.sort((a, b) => a.name.localeCompare(b.name));

      const sortedChamps = [...favoriteChamps, ...remainingChamps];
      setFilteredChamps(sortedChamps);
    }
  }, [champs, favorites]);

  useEffect(() => {
    console.log(favorites);
  }, [favorites]);

  if (!champs) return null;

  return (
    <nav
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: 12,
          paddingBottom: 16,
          background: "#171717",
        }}
      >
        <SearchBar
          onSearch={(searchValue: string) => {
            setFilteredChamps(
              Object.values(champs).filter((champ) =>
                champ.name.toLowerCase().includes(searchValue.toLowerCase())
              )
            );
          }}
        />
        <div>
          <SearchBarSelector />
          <div>Items</div>
          <div>Runes</div>
        </div>
      </div>
      <Sidebar
        backgroundColor="#171717"
        style={{ border: "none", flex: 1, overflow: "scroll" }}
      >
        <Menu
          menuItemStyles={{
            button: {
              paddingLeft: 12,
              paddingRight: 12,
              ":hover": {
                backgroundColor: "rgba(125, 121, 109, 0.2)",
              },
            },
            icon: {
              padding: 2,
              height: 41,
              width: 41,
            },
          }}
        >
          {filteredChamps.map((champ) => (
            <MenuListItem
              key={`${champ.id}-${isFavorite(champ.id)}`}
              name={champ.name}
              image={champ.sprite}
              subtitle={champ.title}
              itemType="champ"
              selected={decodedPath.includes(champ.name)}
              onPress={() => toggleFavorite(champ)}
              isFavorite={isFavorite(champ.id)}
            />
          ))}
          {/* {itemsArray.map((item) => (
            <MenuListItem
              key={`${item.name}}`}
              name={item.name}
              image={item.imageUrl}
              itemType="item"
              selected={decodedPath.includes(item.name)}
              onPress={console.log}
            />
          ))} */}
        </Menu>
      </Sidebar>
    </nav>
  );
};

export default SideNavigationMenu;
