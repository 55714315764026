import React, { FunctionComponent } from "react";
import * as S from "./styled";

type Props = {
  selected?: boolean;
};

const SearchBarSelector: FunctionComponent<Props> = () => {
  return (
    <S.Container>
      <S.Title>Champs</S.Title>
      {/* FORMATTING FIX */}
      {/* FORMATTING FIX */}
    </S.Container>
  );
};

export default SearchBarSelector;
